<template>
  <div>
    <div class="formBox" :style="{height: type != 'workflow'?'calc(100vh - 251px)':''}">
      <div>
        <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="160px">
          <head-layout
            head-title="基本信息"
            style="margin-bottom: 12px"
            :head-btn-options="headBtnOptions"
          ></head-layout>
          <el-row>
            <el-col :span="12">
              <el-form-item label="项目编码" prop="projectCode">
                <template slot="label">
                  <span>项目编码:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.projectCode"
                  controls-position="right"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计日期" prop="statDate">
                <template slot="label">
                  <span style="color:red">* </span>
                  <span>统计日期:</span>
                </template>
                <el-date-picker
                  v-model="dataForm.statDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :disabled="type === 'view'"
                  @change="chooseDate"
                  placeholder="请选择统计日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="填报日期" prop="updateTime">
                <template slot="label">
                  <span>填报日期:</span>
                </template>
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="dataForm.updateTime"
                  type="date"
                  disabled="true"
                  placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="项目名称" prop="projectName">
                <template slot="label">
                  <span>项目名称:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.projectName"
                  controls-position="right"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业务类型" prop="businessTypeName">
                <template slot="label">
                  <span>业务类型:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.businessTypeName"
                  controls-position="right"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目状态:" prop="projectStatusName">
                <el-select
                  v-model="dataForm.projectStatusName"
                  placeholder=""
                  :popper-append-to-body="false"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in projectStatusList"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="州/省" prop="provinceName">
                <template slot="label">
                  <span>州/省:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.provinceName"
                  controls-position="right"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目经理" prop="pmCame">
                <template slot="label">
                  <span>项目经理:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.pmCame"
                  controls-position="right"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="phoneNumber">
                <template slot="label">
                  <span>联系电话:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.phoneNumber"
                  controls-position="right"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总人数" prop="totalCount">
                <template slot="label">
                  <span>总人数:</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="totalCount"
                  controls-position="right"
                  style="width: calc(100% - 30px)"
                  placeholder=""
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="totalCountS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <head-layout
          head-title="项目人员信息"
        ></head-layout>
        <el-form :model="dataForm" :rules="rules" ref="formLayout1" label-width="160px" class="demo-ruleForm"
                 style="margin-top: 20px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="公司在册职工" prop="regeditedChineseCnt">
                <el-input
                  v-model="dataForm.regeditedChineseCnt"
                  controls-position="right"
                  placeholder=""
                  style="width: calc(100% - 30px)"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="regeditedChineseCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分包商(含劳务)人数" prop="subcontractorChineseCnt">
                <el-input
                  v-model="dataForm.subcontractorChineseCnt"
                  controls-position="left"
                  placeholder=""
                  style="width: calc(100% - 30px)"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="subcontractorChineseCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="人员增减说明:" prop="remark1">
                <el-input v-model="dataForm.remark1" type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                          placeholder="" maxlength="200" :show-word-limit="true"
                          :readonly="type == 'view'" style="width: calc(100% - 30px)"></el-input>
                <i class="el-icon-refresh iconLine" @click="remark1"></i>
              </el-form-item>
            </el-col>
          </el-row>
          <head-layout
            head-title="项目现场信息"
            style="margin-bottom: 12px"
          ></head-layout>
          <el-row>
            <el-col :span="24">
              <el-form-item label="项目进展情况" prop="projectProgress">
                <el-input v-model="dataForm.projectProgress" type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                           maxlength="200" :show-word-limit="true"
                          :readonly="type == 'view'" style="width: calc(100% - 30px)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button v-if="type != 'view'" type="primary" @click="headSave">保 存</el-button>
        <el-button @click="headCancel">取 消</el-button>
      </div>
    </div>
  </div>
</template>
<script>

import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {saveDomestic, getDomesticDetail, update, getDetailByDate} from "@/api/foreignReport/domesticReport";
import {mapGetters} from "vuex";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {dictionaryBiz} from "@/api/reportTasks";


export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  mixins: [exForm, draft],
  props: {
    formData: {
      type: Object
    }
  },
  data() {
    return {
      endData: "", //上次时间
      startData: "", // 这次时间
      personnel: "", // 这次时间
      totalCountS: '',
      regeditedChineseCntS: '',
      subcontractorChineseCntS: '',
      projectStatusList: [],
      nodeId: '',
      processTemplateKey: "report_domestic",
      oldDate: "",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
        deptId: '',
      },
      type: '',
      formDialog: false,
      ruleForm: {},
      rules: {
        regeditedChineseCnt: [
          {required: true, message: '请输入公司在册职工', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        subcontractorChineseCnt: [
          {required: true, message: '请输入分包商中方员工数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
      },
      dataForm: {},
      orgCode: "",
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let buttonBtn = [];
      return buttonBtn;
    },
    form1Column() {
      return [
        {
          label: "公司在册职工",
          prop: "regeditedChineseCnt",
          labelWidth: 300,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入公司在册职工数",
            trigger: "blur"
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: '请输入正整数',
            trigger: "blur"
          }]
        },
        {
          label: "分包商（含劳务）人数",
          prop: "subcontractorChineseCnt",
          labelWidth: 300,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入分包商（含劳务）人数",
            trigger: "blur"
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: '请输入正整数',
            trigger: "blur"
          }]
        },
      ]
    },
    form2Column() {
      return [
        {
          label: "项目进展情况",
          prop: "projectProgress",
          labelWidth: 120,
          type: 'textarea',
          maxlength: 500,
          span: 24,
          showWordLimit: true
        },
      ]
    },
    totalCount() {
      let number = 0;
      const regeditedChineseCnt = this.dataForm.regeditedChineseCnt && this.dataForm.regeditedChineseCnt.toString().replace(/\D/g, '');
      const subcontractorChineseCnt = this.dataForm.subcontractorChineseCnt && this.dataForm.subcontractorChineseCnt.toString().replace(/\D/g, '');
      if (regeditedChineseCnt || subcontractorChineseCnt) {
        number = (regeditedChineseCnt ? Number(regeditedChineseCnt) : 0)
          + (subcontractorChineseCnt ? Number(subcontractorChineseCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.totalCount = number;
      return number;
    },
  },
  methods: {
    remark1() {
      if (this.dataForm.referenceStatistical) {
        if (this.dataForm.remark1) {
          this.personnel = this.dataForm.remark1.split('：')[0]
        } else {
          this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
        }
        let regedited = this.dataForm.regeditedChineseCnt - this.dataForm.regeditedChineseCntS
        let subcontractor = this.dataForm.subcontractorChineseCnt - this.dataForm.subcontractorChineseCntS
        this.dataForm.remark1 = this.personnel + (regedited == 0 ? "" : (regedited > 0 ? "增加" : "减少") + Math.abs(regedited) + "名公司在册职工.") + (subcontractor == 0 ? "" : (subcontractor > 0 ? "增加" : "减少") + Math.abs(subcontractor) + "名分包商（含劳务）人数.")
      } else {
        this.$message.warning("参考数据不是已审核通过数据,需手动填写!")
      }
    },
    chooseDate(e) {
      if (this.dataForm.referenceStatistical) {
        if (this.startData) {
          this.endData = e;
          if (this.startData == this.endData) {
            this.selectList();
          } else {
            this.dataForm.remark1 = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
            this.dataForm.remark2 = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
            this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
          }
        }
      }
    },
    getDataForm(row, nodeId) {
      this.nodeId = nodeId;
      let id = row.id
      if (id && id != -1) {
        getDomesticDetail(id).then((res) => {
          if (res.data.code === 200) {
            this.startData = res.data.data.statDate
            this.endData = res.data.data.statDate
            this.dataForm = res.data.data;
            this.orgCode = res.data.data.orgCode;
            this.totalCountS = this.dataForm.totalCountS
            this.regeditedChineseCntS = this.dataForm.regeditedChineseCntS
            this.subcontractorChineseCntS = this.dataForm.subcontractorChineseCntS
          }
        })
      } else {
        this.dataForm.id = null
      }
    },
    closeFormDialog() {
      this.$emit('childMethod');
      this.formDialog = false
    },
    selectList() {
      if (this.dataForm.id && this.dataForm.statDate) {
        this.dataForm.dateSource = 1;
        getDetailByDate(this.dataForm).then((res) => {
          if (res.data.data.id) {
            this.dataForm = res.data.data;
          }
        })
      }
    },
    rpTaskAttributeSubmit(data) {
      this.dataForm.totalCount = this.totalChineseCnt
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 1
      this.formProcess.id = data.id;
      this.formProcess.deptId = this.nodeId
      this.formProcess.paperName = this.dataForm.paperName;
      this.$loading().close();
      this.handleStartProcess5(true,true).then((res) => {
        data.dataStateType = this.type
        data.processInsId = res.data.data;
        data.dataState = 2; // 设置状态为新增生成 审核中
        saveDomestic(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.formDialog = false;
          }
        })
      });
    },
    headSave(clo = false) {
      this.$refs.formLayout1.validate((valid1) => {
        if (valid1) {
          if (this.dataForm.statDate) {
            if (!this.dataForm.id) {
              this.dataForm.createUserName = this.userInfo.real_name
            }
            this.dataForm.updateUserName = this.userInfo.real_name
            this.dataForm.projectArea = 1
            this.dataForm.dataStateType = this.type
            this.dataForm.processInsId = null
            this.$loading()
            update(this.dataForm).then((res) => {
              if (res.data.code === 200) {
                this.dataForm = res.data.data;
                this.$message.success("保存成功");
              }
            }).finally(() => {
              this.$loading().close();
            }).then(() => {
              // if (clo) {
              //   this.formDialog = false;
              // }
            })
          } else {
            this.$message.warning("统计日期为空");
          }
        }
      })
    },
    headCancel() {
      this.$emit('domesticDialog');
    },
  },
  created() {
    let type = this.formData.type;
    this.type = type;
  },
  mounted() {
    dictionaryBiz("prj_state").then((res) => {
      this.projectStatusList = res.data.data;
    });
    this.dataForm = JSON.parse(this.formData.row);
    this.dataForm.orgCode = this.formData.orgCode;
    this.nodeId = this.formData.orgCode;
  },
}
</script>

<style scoped lang="scss">
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  border-top: 1px solid rgb(204, 204, 204);
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

::v-deep .el-form-item__content {
  display: flex;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}

.formBox {
  //height: calc(100vh - 251px);
  overflow: auto;
  overflow-x: hidden;
  margin-left: 6px;
  font-size: 24px;
  padding: 4px 0;
}
</style>
