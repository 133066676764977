var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _vm.type != "workflow"
            ? _c(
                "div",
                [
                  _c("CommonTree", {
                    attrs: {
                      treeData: _vm.treeData,
                      defaultProps: _vm.defaultProps,
                      searchTitle: _vm.searchTitle,
                      treeTitle: "组织架构",
                      isShowdig: false,
                      showCheckbox: false,
                      expandOnClickNode: false,
                      treeExpand: false,
                      currentNodeKey: _vm.currentNodeKey,
                      defaultExpandedKeys: _vm.defaultExpandedKeys,
                      nodeKey: "id",
                      defaultCheckedKeys: _vm.defaultCheckedKeys,
                      loading: _vm.treeLoading,
                    },
                    on: { getNodeClick: _vm.treeNodeClick },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-main",
            {
              staticStyle: {
                position: "relative",
                height: "calc(100vh - 94px)",
              },
            },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "安全活动统计报表",
                },
                on: {
                  "head-summary": _vm.headCollect,
                  "head-update": _vm.headUpdate,
                  "head-submit": _vm.headSubmit,
                  "head-add": _vm.headAdd,
                  "head-reportView": _vm.headReportView,
                },
              }),
              _c(
                "div",
                { staticClass: "head-picker" },
                [
                  _c("el-date-picker", {
                    staticClass: "searchInput",
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "month",
                      format: "yyyy-MM",
                      "value-format": "yyyy-MM",
                      size: "mini",
                      disabled: _vm.dateAndTimeDisabled,
                    },
                    on: { change: _vm.selectList },
                    model: {
                      value: _vm.timeData,
                      callback: function ($$v) {
                        _vm.timeData = $$v
                      },
                      expression: "timeData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "200px",
                  },
                },
                [
                  _c("head-layout", { attrs: { "head-title": "基本信息" } }),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "组织名称", prop: "orgName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.ruleForm.orgName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "orgName", $$v)
                                  },
                                  expression: "ruleForm.orgName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "数据状态", prop: "dataState" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.ruleForm.dataState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "dataState", $$v)
                                    },
                                    expression: "ruleForm.dataState",
                                  },
                                },
                                _vm._l(_vm.dataState, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "活动类型", prop: "actionType" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "", disabled: "" },
                                  model: {
                                    value: _vm.ruleForm.actionType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "actionType", $$v)
                                    },
                                    expression: "ruleForm.actionType",
                                  },
                                },
                                _vm._l(_vm.actionType, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "填报日期", prop: "updateTime" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  disabled: "true",
                                  placeholder: "保存后自动生成",
                                },
                                model: {
                                  value: _vm.ruleForm.updateTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "updateTime", $$v)
                                  },
                                  expression: "ruleForm.updateTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("head-layout", {
                    attrs: { "head-title": '"零违章"人员信息' },
                  }),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: '"零违章"个人总数',
                                prop: "monthPeopleCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: _vm.disabled,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.monthPeopleCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "monthPeopleCnt",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.monthPeopleCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.monthPeopleCntS < 0 ||
                                      _vm.ruleForm.monthPeopleCntS == null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.monthPeopleCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "其中一线员工数量",
                                prop: "monthFirstlinePeopleCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: _vm.disabled,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.monthFirstlinePeopleCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "monthFirstlinePeopleCnt",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.monthFirstlinePeopleCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.monthFirstlinePeopleCntS <
                                        0 ||
                                      _vm.ruleForm.monthFirstlinePeopleCntS ==
                                        null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.monthFirstlinePeopleCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本年累计人数",
                                prop: "yearPeopleCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: true,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.yearPeopleCnt,
                                  callback: function ($$v) {
                                    _vm.yearPeopleCnt = $$v
                                  },
                                  expression: "yearPeopleCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.yearPeopleCntS < 0 ||
                                      _vm.ruleForm.yearPeopleCntS == null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.yearPeopleCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("head-layout", {
                    attrs: { "head-title": '"零违章"组织信息' },
                  }),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: '"零违章"班组数量',
                                prop: "monthGroupCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: _vm.disabled,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.monthGroupCnt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "monthGroupCnt", $$v)
                                  },
                                  expression: "ruleForm.monthGroupCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.monthGroupCntS < 0 ||
                                      _vm.ruleForm.monthGroupCntS == null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.monthGroupCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: '"零违章"工段数量',
                                prop: "monthWorkSectionCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: _vm.disabled,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.monthWorkSectionCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "monthWorkSectionCnt",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.monthWorkSectionCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.monthWorkSectionCntS < 0 ||
                                      _vm.ruleForm.monthWorkSectionCntS ==
                                        null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.monthWorkSectionCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: '"零违章"车间/部门数量',
                                prop: "monthWorkshopCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.disabled,
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.monthWorkshopCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "monthWorkshopCnt",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.monthWorkshopCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.monthWorkshopCntS < 0 ||
                                      _vm.ruleForm.monthWorkshopCntS == null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.monthWorkshopCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: '月度评选"零违章"组织数量',
                                prop: "monthOrgCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: true,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.monthOrgCnt,
                                  callback: function ($$v) {
                                    _vm.monthOrgCnt = $$v
                                  },
                                  expression: "monthOrgCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.monthOrgCntS < 0 ||
                                      _vm.ruleForm.monthOrgCntS == null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.monthOrgCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本年累计组织数",
                                prop: "yearOrgCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "controls-position": "right",
                                  disabled: true,
                                  maxlength: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.yearOrgCnt,
                                  callback: function ($$v) {
                                    _vm.yearOrgCnt = $$v
                                  },
                                  expression: "yearOrgCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content:
                                      _vm.ruleForm.yearOrgCntS < 0 ||
                                      _vm.ruleForm.monthPeopleCntS == null ||
                                      false
                                        ? "上次填报数据：0"
                                        : "上次填报数据：" +
                                          _vm.ruleForm.yearOrgCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("head-layout", {
                attrs: {
                  "head-title": "下级组织安全活动统计信息",
                  "head-btn-options": _vm.subordinateBtn,
                },
                on: {
                  "head-reject": _vm.reject,
                  "head-urge-processing": _vm.headUrging,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                staticClass: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "safetActionStatSummary",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.crudLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "cell-style": _vm.cellStyle,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return undefined
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title: "安全活动统计报表编辑",
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.showDialog,
                width: "80%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
                close: _vm.globalBoxClose,
              },
            },
            [
              _c("editDialog", {
                ref: "editDialog",
                attrs: { rowQuery: _vm.rowQuery },
                on: { closeDialog: _vm.closeDialog },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }